import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Wallet, 
  ArrowDownCircle, 
  Clock, 
  AlertCircle,
  TrendingUp,
  HistoryIcon,
  ChevronRight,
  IndianRupee,
  Info
} from 'lucide-react';

const MIN_WITHDRAWAL_AMOUNT = 200;

const WalletPage = () => {
  const [totalBalance, setTotalBalance] = useState(0);
  const [confirmedOrders, setConfirmedOrders] = useState([]);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [loading, setLoading] = useState(true);
  const [ordersWithProducts, setOrdersWithProducts] = useState([]);
  const [error, setError] = useState('');
  const [showMinAmountInfo, setShowMinAmountInfo] = useState(false);

  useEffect(() => {
    fetchUserOrders();
    fetchWithdrawalHistory();
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 100
      }
    }
  };
  const fetchProductDetails = async (productId) => {
    try {
      const productDoc = await getDoc(doc(firestore, 'products', productId));
      if (productDoc.exists()) {
        return productDoc.data();
      }
      return null;
    } catch (error) {
      console.error('Error fetching product:', error);
      return null;
    }
  };

  const fetchWithdrawalHistory = async () => {
    try {
      const withdrawalsRef = collection(firestore, 'withdrawals');
      const q = query(
        withdrawalsRef,
        where('userId', '==', auth.currentUser.uid)
      );
      
      const querySnapshot = await getDocs(q);
      const withdrawals = [];
      
      querySnapshot.forEach((doc) => {
        withdrawals.push({ id: doc.id, ...doc.data() });
      });
      
      setWithdrawalHistory(withdrawals);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching withdrawals:', error);
    }
  };

  const fetchUserOrders = async () => {
    try {
      const ordersRef = collection(firestore, 'orders');
      const q = query(
        ordersRef,
        where('userId', '==', auth.currentUser.uid),
        where('status', '==', 'confirmed')
      );
      
      const querySnapshot = await getDocs(q);
      const orders = [];
      const ordersWithProductDetails = [];
      
      for (const doc of querySnapshot.docs) {
        const orderData = { id: doc.id, ...doc.data() };
        orders.push(orderData);
        
        const productDetails = await fetchProductDetails(orderData.productId);
        if (productDetails) {
          ordersWithProductDetails.push({
            ...orderData,
            productDetails: {
              price: productDetails.price,
              rewardDays: productDetails.rewardDays,
              rewardPercentage: productDetails.rewardPercentage,
              name: productDetails.name
            }
          });
        }
      }
      
      setConfirmedOrders(orders);
      setOrdersWithProducts(ordersWithProductDetails);
      calculateTotalBalance(ordersWithProductDetails);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const calculateTotalBalance = (orders) => {
    let balance = 0;
    
    orders.forEach(order => {
      const orderDate = order.createdAt.toDate();
      const currentDate = new Date();
      const daysDifference = Math.floor((currentDate - orderDate) / (1000 * 60 * 60 * 24));
      
      if (daysDifference <= order.productDetails.rewardDays) {
        const daysToCalculate = daysDifference;
        const dailyReturn = order.productDetails.price * (order.productDetails.rewardPercentage);
        const totalReturns = dailyReturn * daysToCalculate;
        
        balance += totalReturns;
      } else {
        const dailyReturn = order.productDetails.price * (order.productDetails.rewardPercentage);
        const totalReturns = dailyReturn * order.productDetails.rewardDays;
        
        balance += totalReturns;
      }
    });

    const totalWithdrawals = withdrawalHistory
      .filter(withdrawal => withdrawal.status === 'completed')
      .reduce((total, withdrawal) => total + withdrawal.amount, 0);

    balance -= totalWithdrawals;
    setTotalBalance(Math.max(0, balance));
  };

  const handleWithdrawalRequest = async () => {
    setError('');
    const amount = parseFloat(withdrawalAmount);

    if (!amount || amount <= 0) {
      setError('Please enter a valid withdrawal amount');
      return;
    }

    if (amount < MIN_WITHDRAWAL_AMOUNT) {
      setError(`Minimum withdrawal amount is ₹${MIN_WITHDRAWAL_AMOUNT}`);
      return;
    }

    if (amount > totalBalance) {
      setError('Insufficient balance');
      return;
    }

    try {
      await addDoc(collection(firestore, 'withdrawals'), {
        userId: auth.currentUser.uid,
        amount: amount,
        status: 'pending',
        createdAt: serverTimestamp(),
        requestedDate: new Date().toISOString()
      });

      setWithdrawalAmount('');
      fetchWithdrawalHistory();
      
      // Show success animation
      const successToast = document.createElement('div');
      successToast.className = 'fixed top-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg transform transition-all duration-500';
      successToast.textContent = 'Withdrawal request submitted successfully!';
      document.body.appendChild(successToast);
      
      setTimeout(() => {
        successToast.remove();
      }, 3000);
    } catch (error) {
      console.error('Error submitting withdrawal request:', error);
      setError('Failed to submit withdrawal request');
    }
  };
  
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-slate-50 to-blue-50">
        <div className="relative">
          <div className="w-16 h-16 border-4 border-blue-200 border-t-blue-600 rounded-full animate-spin"></div>
          <p className="mt-4 text-slate-600 font-medium">Loading your wallet...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 via-blue-50 to-purple-50 p-4 sm:p-6 md:p-8">
      <motion.div 
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-md mx-auto space-y-6 md:max-w-4xl"
      >
        {/* Balance Card - Mobile Optimized */}
        <motion.div 
          variants={itemVariants}
          className="bg-white/90 backdrop-blur-sm rounded-2xl sm:rounded-3xl p-6 sm:p-8 shadow-xl border border-white/20 hover:shadow-2xl transition-shadow duration-300"
        >
          <div className="flex flex-col sm:flex-row items-center justify-between mb-6">
            <div className="flex items-center space-x-4 mb-4 sm:mb-0">
              <motion.div 
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="relative"
              >
                <div className="absolute inset-0 bg-blue-100 rounded-full blur-lg opacity-40"></div>
                <Wallet className="relative text-blue-600" size={40} />
              </motion.div>
              <div>
                <h2 className="text-xl sm:text-2xl font-bold text-slate-800">Your Wallet</h2>
                <p className="text-sm sm:text-base text-slate-600">Available Balance</p>
              </div>
            </div>
            <motion.div 
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
              className="text-center sm:text-right w-full sm:w-auto"
            >
              <h3 className="text-3xl sm:text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                ₹{totalBalance.toFixed(2)}
              </h3>
            </motion.div>
          </div>

          {/* Withdrawal Form - Mobile Enhanced */}
          <div className="space-y-4">
            <div className="relative">
              <IndianRupee className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400" size={16} />
              <input
                type="number"
                value={withdrawalAmount}
                onChange={(e) => setWithdrawalAmount(e.target.value)}
                placeholder="Enter withdrawal amount"
                className="w-full pl-10 pr-12 py-3 text-sm sm:text-base bg-slate-50/50 border border-slate-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              />
              <motion.div
                className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                whileHover={{ scale: 1.1 }}
                onHoverStart={() => setShowMinAmountInfo(true)}
                onHoverEnd={() => setShowMinAmountInfo(false)}
              >
                <Info className="text-slate-400" size={16} />
              </motion.div>
              <AnimatePresence>
                {showMinAmountInfo && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="absolute right-0 top-full mt-2 p-2 sm:p-3 bg-white rounded-lg shadow-lg text-xs sm:text-sm text-slate-600 z-10"
                  >
                    Minimum withdrawal amount is ₹{MIN_WITHDRAWAL_AMOUNT}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            {error && (
              <motion.div
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                className="text-red-500 text-xs sm:text-sm flex items-center space-x-2"
              >
                <AlertCircle size={14} />
                <span>{error}</span>
              </motion.div>
            )}

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleWithdrawalRequest}
              className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white py-3 sm:py-4 rounded-xl flex items-center justify-center space-x-2 text-base sm:text-lg font-medium hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg shadow-blue-500/20 hover:shadow-xl hover:shadow-blue-500/30"
            >
              <span>Request Withdrawal</span>
              <ArrowDownCircle className="animate-bounce" size={18} />
            </motion.button>
          </div>
        </motion.div>

        {/* Active Investments - Mobile Optimized */}
        <motion.div
          variants={itemVariants}
          className="bg-white/90 backdrop-blur-sm rounded-2xl sm:rounded-3xl p-6 sm:p-8 shadow-xl border border-white/20"
        >
          <div className="flex items-center space-x-3 mb-6">
            <TrendingUp className="text-blue-600" size={20} />
            <h3 className="text-xl sm:text-2xl font-bold text-slate-800">Active Investments</h3>
          </div>
          <div className="space-y-4">
            <AnimatePresence>
              {ordersWithProducts.map((order, index) => (
                <motion.div
                  key={order.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ scale: 1.01 }}
                  className="group bg-slate-50/50 hover:bg-white rounded-xl p-4 sm:p-6 border border-slate-200/50 transition-all duration-300 hover:shadow-lg"
                >
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                    <div className="space-y-2 mb-4 sm:mb-0">
                      <h4 className="font-semibold text-slate-800 text-base sm:text-lg group-hover:text-blue-600 transition-colors duration-200">
                        {order.productDetails.name}
                      </h4>
                      <p className="text-xl sm:text-2xl font-bold text-slate-800">
                        ₹{order.productDetails.price}
                      </p>
                      <div className="space-y-1 text-xs sm:text-sm">
                        <p className="text-slate-600 flex items-center space-x-2">
                          <span>Daily Return:</span>
                          <span className="font-medium text-green-600">
                            ₹{(order.productDetails.price * order.productDetails.rewardPercentage).toFixed(2)}
                          </span>
                        </p>
                        <p className="text-slate-600 flex items-center space-x-2">
                          <span>Duration:</span>
                          <span className="font-medium">{order.productDetails.rewardDays} days</span>
                        </p>
                      </div>
                    </div>
                    <div className="w-full sm:w-auto text-left sm:text-right space-y-2">
                      <p className="text-xs sm:text-sm text-slate-500">Started</p>
                      <p className="text-xs sm:text-sm font-medium text-slate-800">
                        {order.createdAt.toDate().toLocaleDateString()}
                      </p>
                      <ChevronRight className="text-slate-400 group-hover:text-blue-600 transition-colors duration-200 hidden sm:block" size={20} />
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </motion.div>

        {/* Withdrawal History - Mobile Optimized */}
        <motion.div
          variants={itemVariants}
          className="bg-white/90 backdrop-blur-sm rounded-2xl sm:rounded-3xl p-6 sm:p-8 shadow-xl border border-white/20"
        >
          <div className="flex items-center space-x-3 mb-6">
            <HistoryIcon className="text-blue-600" size={20} />
            <h3 className="text-xl sm:text-2xl font-bold text-slate-800">Withdrawal History</h3>
          </div>
          <div className="space-y-4">
            <AnimatePresence>
              {withdrawalHistory.length > 0 ? (
                withdrawalHistory.map((withdrawal, index) => (
                  <motion.div
                    key={withdrawal.id}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ scale: 1.01 }}
                    className="group bg-slate-50/50 hover:bg-white rounded-xl p-4 sm:p-6 border border-slate-200/50 transition-all duration-300 hover:shadow-lg"
                  >
                    <div className="flex justify-between items-center">
                      <div className="space-y-2">
                        <motion.p 
                          className="text-lg sm:text-xl font-bold text-slate-800"
                          whileHover={{ scale: 1.05 }}
                        >
                          ₹{withdrawal.amount.toFixed(2)}
                        </motion.p>
                        <div className="flex items-center space-x-2 text-slate-500">
                          <Clock size={14} />
                          <p className="text-xs sm:text-sm">
                            {new Date(withdrawal.requestedDate).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                      <motion.div 
                        whileHover={{ scale: 1.05 }}
                        className={`px-3 py-1 sm:px-4 sm:py-2 rounded-full font-medium text-xs sm:text-sm ${
                          withdrawal.status === 'completed' 
                            ? 'bg-green-100 text-green-700 border border-green-200' 
                            : 'bg-amber-100 text-amber-700 border border-amber-200'
                        }`}
                      >
                        {withdrawal.status.charAt(0).toUpperCase() + withdrawal.status.slice(1)}
                      </motion.div>
                    </div>
                  </motion.div>
                ))
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-center py-8"
                >
                  <motion.div
                    animate={{ 
                      scale: [1, 1.1, 1],
                      rotate: [0, 5, -5, 0]
                    }}
                    transition={{ 
                      duration: 2,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    <HistoryIcon className="mx-auto text-slate-400 mb-4" size={40} />
                  </motion.div>
                  <p className="text-sm sm:text-base text-slate-600">No withdrawal history yet</p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>

        {/* Toast Container */}
        <div id="toast-container" className="fixed top-4 right-4 z-50"></div>
      </motion.div>
    </div>
  );
};

export default WalletPage;