import React, { useState, useEffect } from 'react';
import { 
  collection, 
  query, 
  orderBy, 
  onSnapshot, 
  doc, 
  updateDoc,
  deleteDoc 
} from 'firebase/firestore';
import { firestore } from '../firebase';
import AdminProductForm from './AdminProductForm';
import AdminWithdrawRequests from './AdminWithdrawRequests';
import Modal from 'react-modal';
import { 
  Trash, 
  Box, 
  ShoppingCart, 
  PlusCircle, 
  Clock,
  CheckCircle,
  XCircle,
  Eye,
  ArrowUpRight,
  Loader2
} from 'lucide-react';

const AdminDashboard = () => {
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [withdrawRequests, setWithdrawRequests] = useState([]);
  const [activeTab, setActiveTab] = useState('products');
  const [selectedScreenshot, setSelectedScreenshot] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    
    const productsQuery = query(collection(firestore, 'products'), orderBy('createdAt', 'desc'));
    const ordersQuery = query(collection(firestore, 'orders'), orderBy('createdAt', 'desc'));
    const withdrawRequestsQuery = query(collection(firestore, 'withdrawRequests'), orderBy('createdAt', 'desc'));

    const productsUnsubscribe = onSnapshot(productsQuery, (snapshot) => {
      const productsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsData);
      setIsLoading(false);
    });

    const ordersUnsubscribe = onSnapshot(ordersQuery, (snapshot) => {
      const ordersData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setOrders(ordersData);
    });

    const withdrawRequestsUnsubscribe = onSnapshot(withdrawRequestsQuery, (snapshot) => {
      const withdrawRequestsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setWithdrawRequests(withdrawRequestsData);
    });

    return () => {
      productsUnsubscribe();
      ordersUnsubscribe();
      withdrawRequestsUnsubscribe();
    };
  }, []);

  const handleOrderStatusUpdate = async (orderId, status) => {
    try {
      const orderRef = doc(firestore, 'orders', orderId);
      await updateDoc(orderRef, { status });
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const handleWithdrawStatusUpdate = async (requestId, status) => {
    try {
      const requestRef = doc(firestore, 'withdrawRequests', requestId);
      await updateDoc(requestRef, { status });
    } catch (error) {
      console.error('Error updating withdraw request status:', error);
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await deleteDoc(doc(firestore, 'products', productId));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleScreenshotView = (url) => {
    setSelectedScreenshot(url);
    setImageLoading(true);
    setImageError(false);
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const handleImageError = () => {
    setImageLoading(false);
    setImageError(true);
  };


  const renderOrderStatusBadge = (status) => {
    const statusStyles = {
      pending: {
        bg: 'bg-amber-500',
        icon: <Clock className="mr-1" size={16} />,
        text: 'Pending'
      },
      confirmed: {
        bg: 'bg-emerald-500',
        icon: <CheckCircle className="mr-1" size={16} />,
        text: 'Confirmed'
      },
      rejected: {
        bg: 'bg-rose-500',
        icon: <XCircle className="mr-1" size={16} />,
        text: 'Rejected'
      }
    };

    const { bg, icon, text } = statusStyles[status] || { 
      bg: 'bg-gray-400', 
      icon: null, 
      text: status 
    };

    return (
      <span className={`
        ${bg} text-white px-3 py-1.5 rounded-full flex items-center text-sm font-medium
        transition-transform duration-200 ease-in-out hover:scale-105
      `}>
        {icon} {text}
      </span>
    );
  };

  const ScreenshotModal = () => (
    <Modal
      isOpen={!!selectedScreenshot}
      onRequestClose={() => setSelectedScreenshot(null)}
      className="fixed inset-0 z-50 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black/40 backdrop-blur-sm"
    >
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-900">
            Payment Screenshot
          </h2>
          <button 
            onClick={() => setSelectedScreenshot(null)}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <XCircle className="w-6 h-6" />
          </button>
        </div>
        
        <div className="relative min-h-[300px] flex items-center justify-center bg-gray-50 rounded-lg">
          {imageLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
            </div>
          )}
          
          {imageError ? (
            <div className="text-center text-gray-500">
              <XCircle className="w-12 h-12 mx-auto mb-2 text-gray-400" />
              <p>Failed to load image</p>
              <button 
                onClick={() => handleScreenshotView(selectedScreenshot)}
                className="mt-2 text-blue-500 hover:text-blue-600"
              >
                Try again
              </button>
            </div>
          ) : (
            <img 
              src={selectedScreenshot}
              alt="Payment Screenshot"
              className={`
                max-w-full max-h-[70vh] object-contain rounded
                transition-opacity duration-200
                ${imageLoading ? 'opacity-0' : 'opacity-100'}
              `}
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          )}
        </div>
      </div>
    </Modal>
  );
 return (
    <div className="min-h-screen bg-slate-50 p-8 font-sans">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 tracking-tight">
            Admin Dashboard
          </h1>
          
          <div className="flex bg-white rounded-lg shadow-sm p-1">
            {[
              { name: 'products', icon: <Box className="mr-2" /> },
              { name: 'orders', icon: <ShoppingCart className="mr-2" /> },
              { name: 'withdrawals', icon: <ArrowUpRight className="mr-2" /> },
              { name: 'add', icon: <PlusCircle className="mr-2" /> }
            ].map(tab => (
              <button
                key={tab.name}
                onClick={() => setActiveTab(tab.name)}
                className={`
                  flex items-center px-4 py-2 rounded-md
                  transition-all duration-200 ease-in-out
                  ${activeTab === tab.name 
                    ? 'bg-blue-500 text-white shadow-sm' 
                    : 'text-gray-600 hover:bg-gray-50'
                  }
                `}
              >
                {tab.icon}
                {tab.name.charAt(0).toUpperCase() + tab.name.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {/* Products Grid */}
        {activeTab === 'products' && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {isLoading ? (
              Array(6).fill(0).map((_, i) => (
                <div key={i} className="bg-white rounded-lg shadow-sm animate-pulse">
                  <div className="h-48 bg-gray-200 rounded-t-lg" />
                  <div className="p-4 space-y-3">
                    <div className="h-6 bg-gray-200 rounded w-3/4" />
                    <div className="h-4 bg-gray-200 rounded" />
                    <div className="h-4 bg-gray-200 rounded w-5/6" />
                  </div>
                </div>
              ))
            ) : (
              products.map(product => (
                <div 
                  key={product.id} 
                  className="
                    group bg-white rounded-lg shadow-sm overflow-hidden
                    transition-all duration-300 ease-in-out
                    hover:shadow-md
                  "
                >
                  <div className="relative">
                    <img 
                      src={product.imageUrl} 
                      alt={product.name} 
                      className="
                        w-full h-48 object-cover
                        transition-transform duration-300 ease-in-out
                        group-hover:scale-105
                      "
                    />
                    <button
                      onClick={() => handleDeleteProduct(product.id)}
                      className="
                        absolute top-2 right-2 
                        bg-white/90 text-red-500 p-2 rounded-full
                        opacity-0 translate-x-2
                        transition-all duration-200 ease-in-out
                        group-hover:opacity-100 group-hover:translate-x-0
                        hover:bg-red-500 hover:text-white
                      "
                    >
                      <Trash className="w-4 h-4" />
                    </button>
                  </div>
                  
                  <div className="p-4">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">
                      {product.name}
                    </h3>
                    <p className="text-gray-600 text-sm mb-4">
                      {product.description}
                    </p>
                    
                    <div className="space-y-3">
                      <div className="flex justify-between items-center">
                        <span className="text-xl font-semibold text-blue-600">
                          ₹{product.price.toLocaleString()}
                        </span>
                        <span className="text-sm text-emerald-600 font-medium">
                          {(product.rewardPercentage * 100).toFixed(1)}% Daily
                        </span>
                      </div>
                      
                      <div className="relative h-1.5 bg-gray-100 rounded-full overflow-hidden">
                        <div 
                          className="
                            absolute inset-y-0 left-0 bg-blue-500
                            transition-all duration-300 ease-in-out
                            group-hover:bg-blue-600
                          " 
                          style={{width: `${product.rewardPercentage * 100}%`}}
                        />
                      </div>
                      
                      <div className="flex items-center justify-end text-sm text-gray-500">
                        <Clock className="w-4 h-4 mr-1" />
                        {product.rewardDays} days return period
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}

        {/* Orders Table */}
        {activeTab === 'orders' && (
          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50">
                    {['Order ID', 'User Email', 'Product', 'Transaction ID', 'Status', 'Actions'].map(header => (
                      <th key={header} className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => (
                    <tr 
                      key={order.id} 
                      className={`
                        border-t border-gray-100
                        transition-colors duration-150 ease-in-out
                        hover:bg-gray-50
                      `}
                    >
                      <td className="px-4 py-3 text-sm text-gray-900">
                        <div className="flex items-center space-x-1">
                          <ArrowUpRight className="w-4 h-4 text-blue-500 opacity-0 group-hover:opacity-100 transition-opacity" />
                          <span>{order.id}</span>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-sm text-gray-600">{order.email}</td>
                      <td className="px-4 py-3 text-sm text-gray-600">{order.productId}</td>
                      <td className="px-4 py-3 text-sm text-gray-600">{order.transactionId}</td>
                      <td className="px-4 py-3">{renderOrderStatusBadge(order.status)}</td>
                      <td className="px-4 py-3">
                        <div className="flex items-center space-x-2">
                          {order.screenshotUrl && (
                            <button
                              onClick={() => handleScreenshotView(order.screenshotUrl)}
                              className="
                                inline-flex items-center px-3 py-1.5 text-sm
                                text-blue-600 bg-blue-50 rounded-md
                                transition-all duration-150 ease-in-out
                                hover:bg-blue-100
                              "
                            >
                              <Eye className="w-4 h-4 mr-1" />
                              View
                            </button>
                          )}
                          
                          {order.status === 'pending' && (
                            <>
                              <button 
                                onClick={() => handleOrderStatusUpdate(order.id, 'confirmed')}
                                className="
                                  inline-flex items-center px-3 py-1.5 text-sm
                                  text-emerald-600 bg-emerald-50 rounded-md
                                  transition-all duration-150 ease-in-out
                                  hover:bg-emerald-100
                                "
                              >
                                <CheckCircle className="w-4 h-4 mr-1" />
                                Confirm
                              </button>
                              
                              <button 
                                onClick={() => handleOrderStatusUpdate(order.id, 'rejected')}
                                className="
                                  inline-flex items-center px-3 py-1.5 text-sm
                                  text-rose-600 bg-rose-50 rounded-md
                                  transition-all duration-150 ease-in-out hover:bg-rose-100
                                "
                              >
                                <XCircle className="w-4 h-4 mr-1" />
                                Reject
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

         {/* Withdraw Requests Table */}
         {activeTab === 'withdrawals' && (
          <AdminWithdrawRequests
            withdrawRequests={withdrawRequests}
            handleWithdrawStatusUpdate={handleWithdrawStatusUpdate}
          />
        )}
        
        {/* Add Product Form */}
        {activeTab === 'add' && <AdminProductForm />}

        {/* Screenshot Modal Component */}
        <ScreenshotModal />
      </div>
    </div>
  );
};

export default AdminDashboard;