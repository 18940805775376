import React, { useState, useEffect } from 'react';
import { 
  collection, 
  query, 
  orderBy, 
  onSnapshot, 
  doc, 
  updateDoc
} from 'firebase/firestore';
import { firestore } from '../firebase';
import { 
  CheckCircle, 
  XCircle 
} from 'lucide-react';

const AdminWithdrawRequests = () => {
  const [withdrawRequests, setWithdrawRequests] = useState([]);

  useEffect(() => {
    const withdrawRequestsQuery = query(collection(firestore, 'withdrawals'), orderBy('createdAt', 'desc'));

    const withdrawRequestsUnsubscribe = onSnapshot(withdrawRequestsQuery, (snapshot) => {
      const withdrawRequestsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setWithdrawRequests(withdrawRequestsData);
    });

    return () => {
      withdrawRequestsUnsubscribe();
    };
  }, []);

  const handleWithdrawStatusUpdate = async (requestId, status) => {
    try {
      const requestRef = doc(firestore, 'withdrawals', requestId);
      await updateDoc(requestRef, { status });
    } catch (error) {
      console.error('Error updating withdraw request status:', error);
    }
  };

  const renderWithdrawStatusBadge = (status) => {
    const statusStyles = {
      pending: {
        bg: 'bg-amber-500',
        icon: <CheckCircle className="mr-1" size={16} />,
        text: 'Pending'
      },
      confirmed: {
        bg: 'bg-emerald-500',
        icon: <CheckCircle className="mr-1" size={16} />,
        text: 'Confirmed'
      },
      rejected: {
        bg: 'bg-rose-500',
        icon: <XCircle className="mr-1" size={16} />,
        text: 'Rejected'
      }
    };

    const { bg, icon, text } = statusStyles[status] || { 
      bg: 'bg-gray-400', 
      icon: null, 
      text: status 
    };

    return (
      <span className={`
        ${bg} text-white px-3 py-1.5 rounded-full flex items-center text-sm font-medium
        transition-transform duration-200 ease-in-out hover:scale-105
      `}>
        {icon} {text}
      </span>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50">
              {['Request ID', 'User Email', 'Amount', 'Status', 'Actions'].map(header => (
                <th key={header} className="px-4 py-3 text-left text-sm font-semibold text-gray-600">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {withdrawRequests.map((request) => (
              <tr 
                key={request.id} 
                className={`
                  border-t border-gray-100
                  transition-colors duration-150 ease-in-out
                  hover:bg-gray-50
                `}
              >
                <td className="px-4 py-3 text-sm text-gray-900">
                  <div className="flex items-center space-x-1">
                    <span>{request.id}</span>
                  </div>
                </td>
                <td className="px-4 py-3 text-sm text-gray-600">{request.email}</td>
                <td className="px-4 py-3 text-sm text-gray-600">₹{request.amount.toLocaleString()}</td>
                <td className="px-4 py-3">{renderWithdrawStatusBadge(request.status)}</td>
                <td className="px-4 py-3">
                  {request.status === 'pending' && (
                    <div className="flex items-center space-x-2">
                      <button 
                        onClick={() => handleWithdrawStatusUpdate(request.id, 'confirmed')}
                        className="
                          inline-flex items-center px-3 py-1.5 text-sm
                          text-emerald-600 bg-emerald-50 rounded-md
                          transition-all duration-150 ease-in-out
                          hover:bg-emerald-100
                        "
                      >
                        <CheckCircle className="w-4 h-4 mr-1" />
                        Confirm
                      </button>
                      
                      <button 
                        onClick={() => handleWithdrawStatusUpdate(request.id, 'rejected')}
                        className="
                          inline-flex items-center px-3 py-1.5 text-sm
                          text-rose-600 bg-rose-50 rounded-md
                          transition-all duration-150 ease-in-out hover:bg-rose-100
                        "
                      >
                        <XCircle className="w-4 h-4 mr-1" />
                        Reject
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminWithdrawRequests;